import React from "react"
import SectionHeader from "./sectionHeader"
import RichText from "./richText"
import ImageSlider from "./imageSlider"

import styles from "./textImageSlider.module.scss"

const TextImageSlider = (props) => {
  return (
    <section className={styles.textImageSlider} data-background={props.background}>
      <div className="grid">
        {(props.sectionTitle || props.sectionSubtitle || props.body) &&
          <div className="gridItem large-10 large-offset-1">
            {(props.sectionTitle || props.sectionSubtitle) && <SectionHeader
              title={props.sectionTitle}
              subtitle={props.sectionSubtitle}
            />}
            <div className={styles.textImageSliderContent}>
              <RichText blocks={props.body} />
            </div>
          </div>
        }
        <div className="gridItem">
          <ImageSlider images={props.images} columns={props.columns} />
        </div>
      </div>
    </section>
  )
}

export default TextImageSlider
