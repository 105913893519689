import React from "react"
import Figure from "./figure"
import Button from "./button"

import styles from "./quote.module.scss"

const Quote = (props) => {
  const block = props.quote || props.testimonial
  const quote = block.quote || block.testimonial
  const image = block.image || block.logo
  const detail = block.position || block.company

  return (
    <section className="grid">
      <div className="gridItem large-10 large-offset-1 xlarge-8 xlarge-offset-2">
        <div className={`${styles.quote} ${block._type === "team" ? styles.quotePerson : ""}`} data-noborder={props.noBorder}>
          <Figure {...image} />
          <div className={styles.quoteContent}>
            <p>&ldquo;{quote}&rdquo;</p>
            <span>{block.name}{detail ? `, ${detail}` : null}</span>
          </div>
          {block.videoTestimonial && <div className={styles.quoteButton}>
            <Button link={block.videoTestimonial} label="Watch the video testimonial" type="brandDarkGhost" linkType="videoLink" />
          </div>}
        </div>
      </div>
    </section>
  )
}

export default Quote
