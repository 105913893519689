import React from "react"
import RichText from "./richText"

import styles from "./pbRichText.module.scss"

const PbRichText = props => {
  return (
    <section className="grid">
      <div className="gridItem xlarge-10 xlarge-offset-1">
        <div className={styles.richTextSection} data-noborder={props.noBorder}>
          <RichText blocks={props.blocks} />
        </div>
      </div>
    </section>
  )
}

export default PbRichText
