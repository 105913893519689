import BaseBlockContent from "@sanity/block-content-to-react"
import React from "react"
import Tabs from "./tabs"
import Cards from "./cards"
import LogoGrid from "./logoGrid"
import CTA from "./cta"
import TextSection from "./textSection"
import MultiTextImageSection from "./multiTextImageSection"
import TestimonialSlider from "./testimonialSlider"
import Quote from "./quote"
import TextImageSlider from "./textImageSlider"
import DataSourceGrid from "./dataSourceGrid"
import TextImageSection from "./textImageSection"
import Form from "./form"
import HeadshotsSection from "./headshotsSection"
import IconGrid from "./iconGrid"
import PbRichText from "./pbRichText"

const serializers = {
  types: {
    tabsSection(props) {
      return <Tabs {...props.node} />
    },
    cardGrid(props) {
      return <Cards {...props.node} />
    },
    logoGrid(props) {
      return <LogoGrid {...props.node} />
    },
    cta(props) {
      return <CTA {...props.node} />
    },
    globalCta(props) {
      return <CTA {...props.node.cta.cta} />
    },
    textSection(props) {
      return <TextSection {...props.node} />
    },
    multiTextImageSection(props) {
      return <MultiTextImageSection {...props.node} />
    },
    testimonialSection(props) {
      return <TestimonialSlider {...props.node} />
    },
    teamQuote(props) {
      return <Quote {...props.node} />
    },
    videoTestimonial(props) {
      return <Quote {...props.node} />
    },
    textWithImageSlider(props) {
      return <TextImageSlider {...props.node} />
    },
    dataSourceGrid(props) {
      return <DataSourceGrid {...props.node} />
    },
    textImageSection(props) {
      return <TextImageSection {...props.node} />
    },
    formSection(props) {
      return <Form height={"900px"} {...props.node} fullwidth />
    },
    headshotsSection(props) {
      return <HeadshotsSection {...props.node} />
    },
    iconGrid(props) {
      return <IconGrid {...props.node} />
    },
    pbRichText(props) {
      return <PbRichText {...props.node} blocks={props.node.richText} />
    },
  },
}

const PageBuilder = ({ blocks }) => (
  <BaseBlockContent blocks={blocks} serializers={serializers} />
)

export default PageBuilder
