import React from "react"
import { Link } from "gatsby"
import Icon from "./icon"
import RichText from "./richText"

import styles from "./card.module.scss"

const Card = (props) => {
  const content = (card) => (
    <>
      <Icon icon={card.icon} />
      <h3>{card.cardTitle}</h3>
      <RichText blocks={card.cardBody} />
    </>
  )

  return (
    <article className={styles.card} data-icon={props.iconColor}>
      {props.cardLink ? (
        <Link className={styles.cardContent} to={props.cardLink}>
          {content(props)}
          <span>{props.cardLinkLabel}</span>
        </Link>
      ) : (
        <div className={styles.cardContent}>{content(props)}</div>
      )}
    </article>
  )
}

export default Card
