import React from "react"
import SectionHeader from "./sectionHeader"
import Card from "./card"

import styles from "./cards.module.scss"

const Cards = (props) => {
  const sectionID = props.sectionID ? {["id"]: props.sectionID.current} : null
  return (
    <section className={styles.cardSection} data-background={props.background} {...sectionID}>
      <div className={styles.cardSectionInner}>
        <SectionHeader
          title={props.sectionTitle}
          subtitle={props.sectionSubtitleTitle}
          content={props.sectionIntro}
        />
        <div className={styles.cards}>
          <div className={styles.cardsInner}>
            {props.cards && props.cards.map(card =>
              <Card key={card._key} {...card} />
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Cards
