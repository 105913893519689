import React from "react"
import Panel from "./panel"

import styles from "./textImageSection.module.scss"
import cx from "classnames"

const TextImageSection = props => {
  return (
    <section
      id={props?.id}
      className={cx(styles.textImageSectionWrapper, "grid")}
    >
      <div className="gridItem xlarge-10 xlarge-offset-1">
        <div
          className={cx(
            styles.textImageSection,
            props.image.caption && styles.textImageWithCaption
          )}
          data-noborder={props.noBorder}
        >
          <Panel
            title={props.sectionTitle}
            body={props.text}
            links={props.links}
            image={props.image}
            shadow
            reverseLayout={props.reverse}
          />
        </div>
      </div>
    </section>
  )
}

export default TextImageSection
