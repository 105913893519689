import React, { Component } from "react"
import Slick from "react-slick"
import Figure from "./figure"
import Blockquote from "./blockquote"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./testimonialSlider.scss"

class TestimonialSlider extends Component {
  state = {
    mainSlider: null,
    navSlider: null,
  }

  componentDidMount() {
    this.setState({
      mainSlider: this.mainSlider,
      navSlider: this.navSlider
    })
  }

  componentWillUnmount() {
    this.setState({
      mainSlider: null,
      navSlider: null
    })
  }

  render() {
    const ArrowLeft = ({currentSlide, slideCount, children, ...props}) => {
      return <button {...props} className="sliderArrow prev" aria-label="Previous slide" />
    }

    const ArrowRight = ({currentSlide, slideCount, children, ...props}) => {
      return <button {...props} className="sliderArrow next" aria-label="Next slide" />
    }
    return (
      <section className="testimonials">
        <Slick
          asNavFor={this.state.mainSlider}
          ref={slider => (this.navSlider = slider)}
          prevArrow={<ArrowLeft />}
          nextArrow={<ArrowRight />}
          className="navSlider"
          centerMode={true}
          responsive={[{
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              centerPadding: `16px`
            }
          }, {
            breakpoint: 640,
            settings: {
              slidesToShow: 1
            }
          }]}
          slidesToShow={5}
          swipeToSlide={true}
          focusOnSelect={true}
        >
          {this.props.testimonials.map(slide =>
            <Figure key={slide._id} {...slide.logo} />
          )}
        </Slick>
        <Slick
          asNavFor={this.state.navSlider}
          ref={slider => (this.mainSlider = slider)}
          arrow={false}
          className="mainSlider"
          fade={true}
        >
          {this.props.testimonials.map(slide => <div key={slide._id}>
            <Blockquote {...slide} />
          </div>)}
        </Slick>
      </section>
    )
  }
}

export default TestimonialSlider
