import React from "react"
import FormattedLink from "./link"

import styles from "./blockquote.module.scss"

const Blockquote = (props) => {
  return (
    <div className={styles.blockquote}>
      <blockquote>
        <p>&ldquo;{props.testimonial}&rdquo;</p>
        <span>{props.name ? `${props.name}, ${props.company}` : props.company}</span>
      </blockquote>
      {props.link && <div className={styles.blockquoteLink}><FormattedLink linkUrl={props.link} linkLabel={props.label} /></div>}
      {!props.link && props.videoTestimonial && <div className={styles.blockquoteLink}><FormattedLink linkUrl={props.videoTestimonial} linkLabel="Watch the video testimonial" linkType="videoLink" /></div>}
    </div>
  )
}

export default Blockquote
