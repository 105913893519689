import React from "react"
import { Link } from "gatsby"
import { LinkTest } from "../helpers/linkTest"
import Figure from "./figure"

import styles from "./logoList.module.scss"

const LogoList = (props) => {
  const renderLink = (logo) => {
    let linkType = logo.link ? LinkTest(logo.link) : "none"
    if (linkType === "internal") {
      return <Link to={logo.link}><Figure {...logo.logo} /></Link>
    } else if (linkType === "external") {
      return <a href={logo.link} target="_blank" rel="nofollow noopener noreferrer"><Figure {...logo.logo} /></a>
    } else {
      return  <Figure {...logo.logo} />
    }
  }

  return (
    <ul className={styles.logoList} data-type={props.type}>
      {props.logos.map(logo =>
        <li key={logo._key}>
          {renderLink(logo)}
        </li>
      )}
    </ul>
  )
}

export default LogoList
