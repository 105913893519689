import React from "react"
import SectionHeader from "./sectionHeader"
import RichText from "./richText"
import Link from "./link"

import styles from "./textSection.module.scss"

const TextSection = props => {
  return (
    <section
      id={props?.id}
      className={styles.textSection}
      data-background={props.background}
    >
      <div className="grid">
        <div className="gridItem xlarge-10 xlarge-offset-1">
          <div className={styles.textSectionInner}>
            {(props.sectionTitle ||
              props.sectionSubtitle ||
              props.sectionIntro) && (
              <SectionHeader
                title={props.sectionTitle}
                subtitle={props.sectionSubtitle}
                content={props.sectionIntro}
                width="full"
              />
            )}
            <div
              className={`${styles.textSectionBody} ${
                props.bodyWidth === "Restricted"
                  ? styles.textSectionBodyRestricted
                  : ""
              }`}
            >
              <RichText blocks={props.text} />
            </div>
            {props.sectionLink && props.sectionLink.linkLabel && (
              <Link {...props.sectionLink} />
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default TextSection
