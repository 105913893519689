import React from "react"
import SectionHeader from "./sectionHeader"
import Figure from "./figure"
import RichText from "./richText"
import FormattedLink from "./link"
import Button from "./button"

import styles from "./iconGrid.module.scss"

const IconGrid = (props) => {
  return (
    <section className="grid">
      <div className="gridItem xlarge-10 xlarge-offset-1">
        <div className={styles.iconGrid}>
          {(props.sectionTitle || props.sectionSubtitle || props.sectionIntro) &&
            <SectionHeader
              title={props.sectionTitle}
              subtitle={props.sectionSubtitle}
              intro={props.sectionIntro}
            />
          }
          {props.iconCards &&
            <ul className={styles.iconGridList}>
              {props.iconCards.map(card =>
                <li key={card._key}>
                  {card.icon && <Figure {...card.icon} />}
                  {card.title && <h4>{card.title}</h4>}
                  {card.body && <RichText blocks={card.body} />}
                  {card.link && card.link.linkUrl && <FormattedLink {...card.link} />}
                </li>
              )}
            </ul>
          }
          {props.linkUrl && <div className={styles.iconGridLink}>
            <Button link={props.linkUrl} label={props.linkLabel} />
          </div>}
        </div>
      </div>
    </section>
  )
}

export default IconGrid
