import React from "react"
import Tilt from "react-parallax-tilt"
import Figure from "./figure"

import styles from "./logoListDropshadow.module.scss"

const LogoListDropshadow = props => {
  return (
    <ul className={styles.logoList}>
      {props.items.map(item => (
        <li key={item._id}>
          {item.link ? (
            <a
              href={item.link}
            >
              <Tilt tiltMaxAngleX="5" tiltMaxAngleY="5" tiltReverse="true">
                <Figure {...item.logo} />
              </Tilt>
              {props.type === "full" && (
                <>
                  <h3>{item.name}</h3>
                  {item.description && <p>{item.description}</p>}
                  <p className={styles.button}>
                    {item.buttonLabel || "Learn more"}
                  </p>
                </>
              )}
            </a>
          ) : (
            <>
              <Figure {...item.logo} />
              {props.type === "full" && (
                <>
                  <h3>{item.name}</h3>
                  {item.description && <p>{item.description}</p>}
                </>
              )}
            </>
          )}
        </li>
      ))}
    </ul>
  )
}

export default LogoListDropshadow
