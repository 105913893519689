import React, { Component } from "react"
import TextImageSection from "./multiTextImageSectionContent"
import Figure from "./figure"

import styles from "./multiTextImageSection.module.scss"

class MultiTextImageSection extends Component {
  componentDidMount() {
    this.setUpScrollEvent()
    window.addEventListener("resize", this.setUpScrollEvent)
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll)
  }

  setUpScrollEvent = () => {
    let width = window.innerWidth
    width > 1024
      ? window.addEventListener("scroll", this.onScroll)
      : window.removeEventListener("scroll", this.onScroll)
  }

  containerPosition = () => {
    const offset = 92
    let distance = this.container.getBoundingClientRect().top
    let totalDistance = window.scrollY + window.innerHeight
    let totalHeight = this.container.offsetTop + this.container.offsetHeight

    if (distance < offset && totalDistance < totalHeight) {
      return this.container.setAttribute("data-fixed", "top")
    } else if (totalDistance > totalHeight) {
      return this.container.setAttribute("data-fixed", "bottom")
    } else {
      return this.container.setAttribute("data-fixed", "none")
    }
  }

  intersectionCallback = entries => {
    const images = this.imageContainer.children
    entries.forEach(entry => {
      let el = entry.target
      let index = [...el.parentNode.children].indexOf(el)

      if (entry.isIntersecting) {
        if (entry.intersectionRatio > 0.35) {
          for (let i = 0; i < images.length; i++) {
            images[i].style.opacity = 0
          }
          images[index].style.opacity = 1
        }
      } else {
        images[index].style.opacity = 0
      }
    })
  }

  displayImage = () => {
    let options = {
      rootMargin: "0px",
      threshold: 0,
    }

    let observer = new IntersectionObserver(this.intersectionCallback, options)
    for (let i = 0; i < this.contentContainer.children.length; i++) {
      observer.observe(this.contentContainer.children[i])
    }
  }

  onScroll = () => {
    this.containerPosition()
    this.displayImage()
  }

  render() {
    const anchorId = this?.props?.id

    return (
      <div id={anchorId} className="grid">
        <div className="gridItem xlarge-10 xlarge-offset-1">
          <div
            className={styles.multiTextImageSection}
            ref={node => (this.container = node)}
          >
            <div
              className={styles.text}
              ref={node => (this.contentContainer = node)}
            >
              {this.props.sections.map(section => (
                <div className={styles.textInner} key={section._key}>
                  <TextImageSection
                    key={`${section._key}_content`}
                    {...section}
                  />
                  <div className={styles.textImage}>
                    <Figure key={`${section._key}_image`} {...section.image} />
                  </div>
                </div>
              ))}
            </div>
            <div className={styles.images}>
              <div
                className={styles.imagesInner}
                ref={node => (this.imageContainer = node)}
              >
                {this.props.sections.map(section => (
                  <Figure key={`${section._key}_image`} {...section.image} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default MultiTextImageSection
