import React, { Component } from "react"
import { slugify } from "../helpers/slugify"
import SectionHeader from "./sectionHeader"
import Panel from "./panel"

import styles from "./tabs.module.scss"

class Tabs extends Component {

  componentDidMount() {
    this.setHeight()
    this.getActiveTab()
  }

  getActiveTab = () => {
    const hash = typeof window !== 'undefined' ? window.location.hash : ''

    if (hash) {
      const items = Array.from(this.tabList.children).map(item => item.firstChild.getAttribute("href"))
      const i = items.indexOf(hash)

      if (i !== -1) return this.setActiveTab(i)

      return this.setActiveTab(0)
    }

    return this.setActiveTab(0)
  }

  setActiveTab = (i) => {
    this.tabList.children[i].firstChild.setAttribute("aria-selected", true)
    this.tabContent.children[i].setAttribute("aria-hidden", false)
  }

  setHeight = () => {
    const panels = this.tabContent.children
    let heights = []

    for (let i = 0; i < panels.length; i++) {
      heights.push(panels[i].offsetHeight)
      panels[i].setAttribute("aria-hidden", true)
    }

    const maxHeight = Math.max.apply(null, heights)

    for (let i = 0; i < panels.length; i++) {
      this.tabContent.style.height = `${maxHeight}px`
    }
  }

  onClickHandler = (evt) => {
    evt.preventDefault()
    // Uncomment to update url on trigger click. N.B. evt.preventDefault will prevent scroll, however, clicking back link will not update active tab
    // window.history.pushState({}, '', evt.currentTarget.href)
    const tabs = this.tabList.children
    const panels = this.tabContent.children

    for (let i = 0; i < tabs.length; i++) {
      if (tabs[i].firstChild === evt.currentTarget) {
        tabs[i].firstChild.setAttribute("aria-selected", true)
        panels[i].setAttribute("aria-hidden", false)
      } else {
        tabs[i].firstChild.setAttribute('aria-selected', false)
        panels[i].setAttribute("aria-hidden", true)
        panels[i].querySelector("h3").focus()
      }
    }
  }

  onMobileClickHandler = (evt) => {
    let button = evt.currentTarget
    button.getAttribute('aria-expanded') === "true" ? button.setAttribute("aria-expanded", "false") : button.setAttribute("aria-expanded", "true")
  }

  render() {
    return (
      <section className="grid">
        <div className="gridItem xlarge-10 xlarge-offset-1">
          <div className={styles.tabs} data-theme={this.props.theme} data-noborder={this.props.noBorder}>
            <SectionHeader
              title={this.props.sectionTitle}
              subtitle={this.props.sectionSubtitle}
              content={this.props.sectionIntro}
            />
            <ul className={styles.tabList} ref={(node) => {this.tabList = node}}>
              {this.props.tabs.map((tab) =>
                <li key={`trigger_${tab._key}`}>
                  <a href={`#${slugify(tab.tabTitle)}`} id={`tab-${slugify(tab.tabTitle)}`} aria-selected="false" onClick={this.onClickHandler}>{tab.tabTitle}</a>
                </li>
              )}
            </ul>
            <div ref={(node) => {this.tabContent = node}} className={styles.tab}>
              {this.props.tabs.map(tab =>
                <div key={`panel_${tab._key}`} className={styles.tabPanelWrapper} id={slugify(tab.tabTitle)} aria-labelledby={`tab-${slugify(tab.tabTitle)}`}>
                  <Panel
                    title={tab.tabBodyTitle ? tab.tabBodyTitle : tab.tabTitle}
                    body={tab.tabBody}
                    image={tab.tabImage}
                    shadow={tab.noShadow ? false : true}
                  />
                </div>
              )}
            </div>
            {this.props.tabs.map(tab =>
              <div key={`panel_${tab._key}`} className={styles.tabMobile}>
                <button className={styles.tabTrigger} aria-expanded="false" onClick={this.onMobileClickHandler}>{tab.tabTitle}</button>
                <div className={styles.tabContent}>
                  <div className={styles.tabContentInner}>
                    <Panel
                      title={tab.tabBodyTitle ? tab.tabBodyTitle : tab.tabTitle}
                      body={tab.tabBody}
                      image={tab.tabImage}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    )
  }
}

export default Tabs
