import React from "react"
import RichText from "./richText"
import Buttons from "./buttons"
import Figure from "./figure"

import styles from "./panel.module.scss"
import cx from "classnames"

const Panel = props => {
  return (
    <div
      className={cx(styles.panel, props.reverseLayout && styles.reverseLayout)}
    >
      <div className={styles.panelContent}>
        {props.title && <h3>{props.title}</h3>}
        {props.body && <RichText blocks={props.body} />}
        {props.links && <Buttons buttons={props.links} />}
      </div>
      <div
        className={
          props.shadow
            ? `${styles.panelImage} ${styles.panelImageAlt}`
            : styles.panelImage
        }
      >
        <Figure {...props.image} />
      </div>
    </div>
  )
}

export default Panel
