import React from "react"
import SectionHeader from "./sectionHeader"
import LogoList from "./logoListDropshadow"
import FormattedLink from "./link"

import styles from "./dataSourceGrid.module.scss"

const DataSourceGrid = (props) => {
  return (
    <section className="grid">
      <div className="gridItem">
        <div className={styles.dataSourceGrid} data-background={props.background} data-noborder="true">
          <SectionHeader
            title={props.sectionTitle}
            subtitle={props.sectionSubtitle}
            content={props.sectionIntro}
            width="full"
          />
          {props.dataSources && <LogoList items={props.dataSources} type={props.type} />}
          {props.linkUrl && <FormattedLink linkUrl={props.linkUrl} linkLabel={props.linkLabel} />}
        </div>
      </div>
    </section>
  )
}

export default DataSourceGrid
