import React from "react"
import SectionHeader from "./sectionHeader"
import Figure from "./figure"

import styles from "./headshotsSection.module.scss"

const HeadshotsSection = (props) => {
  return (
    <section className="grid">
      <div className="gridItem xlarge-10 xlarge-offset-1">
        <div className={styles.headshotsSection} data-noborder={props.noBorder}>
          <SectionHeader
            title={props.sectionTitle}
            subtitle={props.sectionSubtitle}
            content={props.sectionIntro}
            width="full"
          />
          <ul className={styles.headshotList}>
            {props.headshots.map(headshot =>
              <li key={headshot._key}>
                <Figure {...headshot.image} />
                <h4>{headshot.name}</h4>
                {headshot.position && <p>{headshot.position}</p>}
              </li>
            )}
          </ul>
        </div>
      </div>
    </section>
  )
}

export default HeadshotsSection
