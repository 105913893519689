import React from "react"
import RichText from "./richText"
import Buttons from "./buttons"

import styles from "./multiTextImageSectionContent.module.scss"

const TextImageSection = (props) => {
  return (
    <section className={styles.textImageSection}>
      {props.sectionTitle && <h3>{props.sectionTitle}</h3>}
      {props.sectionSubtitle && <h4>{props.sectionSubtitle}</h4>}
      {props.text && <RichText blocks={props.text} />}
      {props.links && <Buttons buttons={props.links} />}
    </section>
  )
}

export default TextImageSection
