import React from "react"
import SectionHeader from "./sectionHeader"
import LogoList from "./logoList"
import FormattedLink from "./link"

import styles from "./logoGrid.module.scss"

const LogoGrid = (props) => {
  return (
    <section className="grid">
      <div className="gridItem xlarge-10 xlarge-offset-1">
        <div className={styles.logos} data-noborder={props.noBorder}>
          <SectionHeader
            title={props.sectionTitle}
            subtitle={props.sectionSubtitle}
            content={props.sectionIntro}
            width="full"
          />
          {props.logos && <LogoList logos={props.logos} type={props.type} />}
          {props.linkUrl && <FormattedLink linkUrl={props.linkUrl} linkLabel={props.linkLabel} />}
        </div>
      </div>
    </section>
  )
}

export default LogoGrid
